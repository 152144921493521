<template>
  <div>
    <div class="content">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          <el-form>
            工程编号/名称：
            <el-input
              style="width: 200px"
              placeholder="请输入"
              size="small"
              v-model="searchBox.projectStr"
            ></el-input>
            <span style="padding-left: 20px"> 第三方施工队：</span>
            <el-input
                style="width: 200px"
                placeholder="请输入"
                size="small"
                v-model="searchBox.thirdTeam"
            ></el-input>
            <span style="padding-left: 20px"> 工程类型：</span>
            <el-select
              v-model="searchBox.projectType"
              clearable
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span style="padding-left: 20px"> 工程状态：</span>
            <el-select
              v-model="searchBox.projectStatus"
              clearable
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in statustypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            现场管理员：
            <el-input
                style="width: 200px"
                placeholder="请输入"
                size="small"
                v-model="searchBox.siteManager"
            ></el-input>
            <div style="margin-top: 10px">
              <span style="padding-left: 33px"> 提交时间：</span>
              <el-date-picker
                size="small"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="选择开始时间"
                end-placeholder="选择结束时间"
              >
              </el-date-picker>
              <span style="padding-left: 20px"> 巡检结果：</span>
              <el-select
                  v-model="searchBox.checkResult"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="item in resultList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <span style="padding-left: 20px"> 整改状态：</span>
              <el-select
                  v-model="searchBox.inspectionState"
                  clearable
                  placeholder="请选择"
                  size="small"
              >
                <el-option
                    v-for="item in statusAllList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="search"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="SearchBtn"
                size="small"
                @click="resetData"
                >重置</el-button
              >
            </div>
          </el-form>
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 10px 0"
            border
            height="calc(100vh - 390px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectType"
              label="工程类型"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="projectSite"
              label="工程地点"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="thirdTeam"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              width="100"
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="checkTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="checkResult"
              label="巡检结果"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="checkResult"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
             <template slot-scope="{ row }">
              <el-tag  type="primary" v-if="row.inspectionState==-1" style="background-color:#BFBFBF;color:#fff" size="small" plain>无需整改</el-tag>
              <el-tag  type="primary" v-if="row.inspectionState==0" style="background-color:#EFAC0B;color:#fff" size="small" plain>未整改</el-tag>
              <el-tag  type="primary" v-if="row.inspectionState==1" style="background-color:#449BFF;color:#fff" size="small" plain>整改中</el-tag>
              <el-tag  type="primary" v-if="row.inspectionState==3" style="background-color:#32C655;color:#fff" size="small" plain>已整改</el-tag>
             </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center"
              show-overflow-tooltip
              width="100"
            >
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="seeDetil(row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="detilDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <div style="max-height: 600px; overflow: auto">
        <p class="detil-code">工程信息</p>
        <div class="item-list">
          <div style="display: flex; ">
            <div style="flex: 1">
              工程名称：{{ project.partOne.projectName }}
            </div>
            <div style="flex: 1">
              工程类型：{{ project.partOne.projectType }}
            </div>
            <div style="flex: 1">
              现场管理员：{{ project.partOne.siteManager }}
            </div>
          </div>
        </div>
        <p class="detil-code">第三方施工队</p>
        <div class="item-list">
          <div style="display: flex; ">
            <div style="flex: 1">
              安装施工队：{{ project.partOne.installTeam || "-" }}
            </div>
            <div style="flex: 1">
              土方施工队：{{ project.partOne.earthTeam || "-" }}
            </div>
            <div style="flex: 1">
              定向钻施工队：{{ project.partOne.drillTeam || "-" }}
            </div>
          </div>
        </div>
        <p class="detil-code">巡检信息</p>
        <div style="">
          <div style="display: flex">
            <div>现场图片：</div>
            <div>
              <el-image
                style="width: 100px; height: 100px"
                :src="checkImageUrl[0]"
                :preview-src-list="checkImageUrl"
              >
              </el-image>
              <span style="margin-left: 10px"
                >共{{ checkImageUrl.length }}张</span
              >
            </div>
          </div>
          <div style="display: flex">
            <div style="margin-top: 20px; flex: 1">
              现场说明：{{ project.partTwo.checkDescription || "-" }}
            </div>
            <div style="margin-top: 20px; flex: 1">
              巡检结果：{{ project.partTwo.checkResult }}
            </div>
            <div style="margin-top: 20px; flex: 1">
              施工队：{{ project.partTwo.teamList || "-" }}
            </div>
          </div>
        </div>
        <div
          style=""
          v-if="project.partTwo.checkResult == '需要整改'"
        >
          <div style="margin: 20px 0">
            整改原因：{{ project.partTwo.cause }}
          </div>
          <div style="display: flex">
            <div>不合格照片：</div>
            <div>
              <el-image
                style="width: 100px; height: 100px"
                :src="causeImg[0]"
                :preview-src-list="causeImg"
              >
              </el-image>
              <span style="margin-left: 10px">共{{ causeImg.length }}张</span>
            </div>
          </div>
          <div style="display: flex" class="item-list">
            <div style="margin-top: 20px; flex: 1">
              提交人：{{ project.partTwo.checkUserRealname }}
            </div>
            <div style="margin-top: 20px; flex: 1">
              提交时间：{{ project.partTwo.checkTime }}
            </div>
            <div style="margin-top: 20px; flex: 1"></div>
          </div>
        </div>
        <p
          class="detil-code"
          style="margin-bottom: 15px"
          v-if="project.partTwo.checkResult == '需要整改'"
        >
          整改情况
        </p>
        <div
          style=" display: flex"
          v-if="project.partTwo.checkResult == '需要整改'"
        >
          <div v-if="!project.partThree.length">暂无整改信息</div>
          <div
            style="flex: 1"
            v-for="(item, index) in project.partThree"
            :key="index"
          >
            <div class="item-list" style="display: flex; align-items: center">
              <img
                v-if="item.state == 2"
                src="../../assets/choose.png"
                height="16"
                width="16"
              />
              <img
                v-else
                src="../../assets/nochoose.png"
                height="16"
                width="16"
              />
              <span style="margin-left:5px">{{ item.teamType }}</span>
            </div>
            <div class="item-list">
              整改人： <span v-if="item.state == 2">{{ item.dealUser }}</span>
              <span v-else>-</span>
            </div>
            <div class="item-list">
              整改时间：<span v-if="item.state == 2">{{ item.dealTime }}</span>
              <span v-else>-</span>
            </div>
            <div style="display: flex">
              <div>整改图片：</div>
              <div v-if="item.laterImg && item.state == 2">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item.laterImg.split(',')[0]"
                  :preview-src-list="item.laterImg.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ item.laterImg.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchDailyCheckList,
  searchDailyCheckDetail,
  searchDailyCheckInspectionDetail,
  searchDailyCheckProjectDetail,
  projectStatus,
} from "@/RequestPort/report/projectReport";
import { getDict } from "../../apis/commonType";
export default {
  name: "lockout",
  data() {
    return {
      activeName: "1",
      checkImageUrl: [],
      causeImg: [],
      project: {
        partOne: {},
        partTwo: {},
        partThree: [],
      },
      searchBox: {
        current: 1,
        size: 100,
        name: "",
        projectStr: "",
        thirdTeam: "",
        projectType: "",
        projectStatus: "",
        inspectionState: "",
        checkResult: "",
      },
      time: [],
      total: null,
      detilDialog: false,
      typeList: [],
      statustypeList: [],
      resultList: [
        {name:'正常',id:1},{name:'需要整改',id:2}
      ],
      statusAllList: [
        {name:'未整改',id:0},{name:'整改中',id:1},{name:'无需整改',id:2},{name:'已整改',id:3}
      ],
      tableData: [{}],
    };
  },
  mounted() {
    //字典查工程类型
    getDict({ parentCode: "2100" }).then((res) => {
      this.typeList = res.data;
    });
    //查状态
    projectStatus().then((res) => {
      this.statustypeList = res.data;
    });

    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let t1 = year + "-" + month + "-" + day + " " + "00:00:00";
    let t2 = year + "-" + month + "-" + day + " " + "23:59:59";
    this.time.push(t1);
    this.time.push(t2);
    if(this.time!=null&&this.time.length>0){
      this.searchBox.startTime = this.time[0]
      this.searchBox.endTime = this.time[1]
    }
    this.loadList(this.searchBox);
  },
  methods: {
    seeDetil(row) {
      this.checkImageUrl =[]
       this.causeImg =[]
      this.detilDialog = true;
      searchDailyCheckDetail({ checkId: row.id }).then((res) => {
        this.project.partTwo = res.data;
        if (res.data.checkImageUrl) {
          this.checkImageUrl = res.data.checkImageUrl.split(",");
        }
        if (res.data.img) {
          this.causeImg = res.data.img.split(",");
        }
      });
      searchDailyCheckInspectionDetail({ checkId: row.id }).then((res) => {
        this.project.partThree = res.data;
      });
      searchDailyCheckProjectDetail({ projectId: row.projectId }).then(
        (res) => {
          this.project.partOne = res.data;
        }
      );
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      if(this.time!=null&&this.time.length>0){
        this.searchBox.startTime = this.time[0]
        this.searchBox.endTime = this.time[1]
      }else {
        this.searchBox.startTime= ''
        this.searchBox.endTime = ''
      }
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    loadList(obj) {
      searchDailyCheckList(obj).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
        name: "",
        projectStr: "",
        thirdTeam: "",
        projectType: "",
        projectStatus: "",
        siteManager: "",
        checkResult: "",
        inspectionState: "",
      };
      this.time = [];
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 0px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
.item-list {
  margin-bottom: 15px;
}
</style>